:root {
  --primary: #ab343c;

  --white: white;

  --black-300: #333;

  --red-400: #ae1515;
  --red-700: #922c33;

  --gray-300: #666;
  --gray-500: #a6a6a6;

  --blue-500: #0000d9;

  --background-form: #f6ebec;
}

@font-face {
  font-family: "Segoe UI";
  src: url("../assets/fonts/Segoe_UI.ttf") format("truetype");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:focus {
  outline: 0;
  box-shadow: 0 0 0 2px var(--gray-500);
}

.bodyRegister {
  font-size: 15px;
  width: 100vw;
  height: 100%;
  background: url(../assets/img/background.png) no-repeat;
  background-size: cover;
}

body,
button,
input,
textarea,
select {
  font-family: "Segoe UI";
  font-weight: 400;
}

footer {
  color:var(--white);
}
/* home */
.containerRegister {
  max-width: 850px;
  margin: auto;
  padding-top: 50px;
}
.containerSend {
  background-color: white;
  width: 850px;
  margin: auto;
  justify-content: space-between;
  gap: 65px;
  padding: 45px 27px 30px 27px;
}

.headerRegister {
  background-color: var(--primary);
  height: auto;
  padding: 50px;
}

.headerLogo {
  display: flex;
  align-items: center;
  gap: 30px;
}

.headerLogo img {
  width: 178px;
  height: 120px;
}

.headerTeste {
  align-items: center;
  gap: 30px;
}

.button_cancel{
  color: #333;
}

.headerLogo h1 {
  color: var(--white);
  font-size: 38px;
  font-weight: 700;
  line-height: 1.3;
  word-wrap: break-word;
  word-break: break-word;
  white-space: pre-wrap;
}

.headerEmail {
  margin-top: 20px;
  padding-left: 25px;
  color: var(--white);
}

.headerEmail a {
  color: var(--white);
}

.headerContent {
  margin-top: 30px;
  color: var(--white);
}

.header footer {
  color: var(--white);
  padding-top: 20px;
}

form {
  background-color: var(--background-form);
  padding: 20px 30px 70px;
}

.row {
  padding: 0 20px;
}

.headerForm {
  color: var(--black-300);
  font-size: 13px;
}

.headerForm span {
  color: var(--red-400);
  display: inline-block;
  margin-right: 5px;
}

.campoObrigatorio {
  color: var(--red-400);
  display: inline-block;
  margin-left: 5px;
}

.containerForm {
  padding: 35px 0;
  display: flex;
  flex-direction: column;
}

.containerForm label {
  display: inline-block;
  margin-bottom: 20px;
  font-size: 17px;
  color: var(--black-300);
}

.containerForm input {
  margin-left: 15px;
  border-color: var(--black-300);
  border-radius: 0;
  background-color: var(--white);
  background-image: none;
  border: 1px solid var(--gray-500);
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: var(--gray-300);
  display: block;
  font-size: 14px;
  height: 40px;
  line-height: 1.42857143;
  padding: 6px 12px;
  transition: border-color 0.15s ease-in-out;
  width: 100%;
}

.containerForm input:focus {
  outline-offset: none;
  outline: none;
  border: 1px solid var(--primary);
}

.containerForm .camp5 {
  height: 80px;
  min-height: 80px;
}

.containerRadio {
  display: flex;
  align-items: center;
}

.containerRadio + .containerRadio {
  margin-top: 20px;
}

.containerRadio input[type="checkbox"] {
  height: 18px;
  width: 20px;
}

.containerRadio label {
  margin-bottom: 0;
  padding-left: 10px;
  font-size: 14px;
  cursor: pointer;
}

.number {
  display: inline-block;
  margin-right: 5px;
}

.termos {
  color: var(--gray-300);
  font-style: italic;
  line-height: 1.4;
  font-size: 14px;
}

.informacao {
  color: var(--gray-300);
  font-style: italic;
  line-height: 1.4;
  font-size: 14px;
}

.containerForm a {
  color: var(--blue-500);
  font-size: 14px;
}

.mt-20 {
  margin-top: 20px;
}

.ml-0 {
  margin-left: 0 !important;
}

button[type="submit"] {
  margin-top: 60px;
  background-color: var(--primary);
  color: var(--white);
  font-size: 15px;
  width: 178px;
  height: 38px;
  cursor: pointer;
  border: none;
  transition: background-color 0.5s;
}

#termosButton {
  font-size: 14px;
  width: 178px;
  height: 38px;
}

button[type="submit"]:hover {
  background-color: var(--red-700);
}

#login {
  width: 100vw;
  height: 100vh;
  background: url(../assets/img/background.png) no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

#sender {
  width: 100vw;
  height: 100vh;
  background: url(../assets/img/background.png) no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.containerLogin {
  width: 850px;
  margin: auto;
}

.headerStyle {
  padding: 57px 57px 57px 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--primary);
}

.headerStyle img {
  width: 168px;
  height: 51px;
}

.headerStyle h1 {
  width: 352px;
  font-weight: 700;
  margin-top: -21px;
  color: var(--white);
  letter-spacing: 0;
  line-height: 30px;
}

#login form {
  height: 336px;
  background-color: var(--white);
  display: flex;
  justify-content: space-between;
  gap: 65px;
  padding: 45px 27px 110px 27px;
}

#sender form {
  height: 336px;
  background-color: var(--white);
  display: flex;
  justify-content: space-between;
  gap: 65px;
  padding: 45px 27px 110px 27px;
}

.loginBox {
  width: 365px;
}

.loginBox h2 {
  color: var(--black-300);
  font-size: 25px;
  font-weight: 700;
  text-align: center;
  width: 100%;
  margin-bottom: 30px;
}

.loginBox label {
  display: block;
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 10px;
}

.loginBox input {
  border-color: var(--black-300);
  border-radius: 0;
  background-color: var(--white);
  background-image: none;
  border: 1px solid var(--gray-500);
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: var(--gray-300);
  width: 100%;
  height: 40px;
  padding: 12px;
  display: block;
  font-size: 12px;
  line-height: 1.42857143;
  transition: border-color 0.15s ease-in-out;
}

.loginBox input:focus {
  outline-offset: none;
  outline: none;
  border: 1px solid var(--primary);
}

.loginBox button[type="submit"] {
  width: 100%;
  height: 40px;
  font-size: 15px;
  font-weight: 700;
  margin-top: 20px;
}

.loginRegis {
  width: 365px;
}

.loginRegis h2 {
  color: var(--black-300);
  font-size: 25px;
  font-weight: 700;
  text-align: center;
  width: 100%;
  margin-bottom: 15px;
}

.loginRegis h3 {
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 25px;
  text-align: center;
}

.loginRegis button[type="submit"] {
  margin-top: 0;
  width: 100%;
  height: 40px;
  font-size: 15px;
  font-weight: 700;
  background-color: transparent;
  color: var(--primary);
  border: 2px solid var(--primary);
  transition: background-color .5s;
}

.loginRegis button[type="submit"]:hover {
  background-color: var(--primary);
  color: var(--white);
  border: 2px solid var(--primary);
}

.custom-modal-termos {
  width: 60% !important; /* Ajuste o valor conforme necessário */
  height: 60% !important; /* Ajuste o valor conforme necessário */
  max-width: none !important; /* Remove o limite máximo de largura */
}

ul.numerada {
  list-style-type: decimal;
}


/* fim home */
